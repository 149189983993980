import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

import { TableModule } from 'primeng/table';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { SpinnerModule } from 'primeng/spinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { KeyFilterModule } from 'primeng/keyfilter';
import { FileUploadModule } from 'primeng/fileupload';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { EditorModule } from 'primeng/editor';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { ChartModule } from 'primeng/chart';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { AccordionModule } from 'primeng/accordion';
import { PageNavigationComponent } from '@shared/components/page-navigation/page-navigation.component';
import { DirectiveModule } from '@shared/directives/directive.module';
import { PipeModule } from '@shared/pipes/pipe.module';

import { JsonEditorComponent } from '@shared/components/json-editor/json-editor.component';
import { MultipleCalendarComponent } from './components/multiple-calendar/multiple-calendar.component';

const ANGULAR_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, GoogleMapsModule];

const PRIMENG_MODULES = [
    TableModule,
    BreadcrumbModule,
    ProgressSpinnerModule,
    CalendarModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    MessageModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    SpinnerModule,
    RadioButtonModule,
    SelectButtonModule,
    InputSwitchModule,
    CheckboxModule,
    TooltipModule,
    AutoCompleteModule,
    OverlayPanelModule,
    KeyFilterModule,
    FileUploadModule,
    MenuModule,
    ToastModule,
    RippleModule,
    VirtualScrollerModule,
    EditorModule,
    InputNumberModule,
    InputTextareaModule,
    InputMaskModule,
    ChartModule,
    ConfirmPopupModule,
    AccordionModule,
    MultiSelectModule
];

@NgModule({
    declarations: [MultipleCalendarComponent, PageNavigationComponent, JsonEditorComponent],
    imports: [...ANGULAR_MODULES, ...PRIMENG_MODULES, PipeModule, DirectiveModule],
    exports: [...ANGULAR_MODULES, ...PRIMENG_MODULES, PipeModule, DirectiveModule, PageNavigationComponent, MultipleCalendarComponent, JsonEditorComponent]
})
export class SharedModule {}
