export enum SessionType {
    Admin = 0,
    Player = 1,
    P5 = 2
}

export enum AdminPermission {
    None = 0,
    ChangeAdminPermission = 1,
    DeletePlayer = 2,
    RestoreFromCompletedStake = 4,
    RestoreFromCanceledStake = 8,
    TransferBalance = 16,
    All = ChangeAdminPermission | DeletePlayer | RestoreFromCompletedStake | RestoreFromCanceledStake | TransferBalance
}

export enum MemberClass {
    Normal = 0,
    Seller = 1
}

export enum PaymentServiceProvider {
    Unknown = 0,
    PayPal = 1,
    Stripe = 2,
    CreditCard = 3,
    CoinPayments = 4,
    Braintree = 5,
    AuthorizeNet = 6,
    Nuvei = 7
}

export enum PSPName {
    Unknown = 0,
    PayPal = 10,
    Stripe = 11,
    CreditCard = 12,
    CoinPayments = 13,
    Braintree = 14,
    AuthorizeNet = 15,
    Nuvei = 16,
    PIX = 17,
    NPay = 18
}

export enum StakeProgress {
    Processing = 0,
    Success = 1,
    Canceled = 2
}

export enum StakeTransactionProgress {
    Purchase = 0,
    Success = 1,
    Failed = 2,
    Refund = 3,
    Pending = 4,
    Canceled = 5
}

export enum TransactionType {
    Deposit = 0,
    Withdrawal = 1,
    WithdrawalCanceled = 2,
    WithdrawalFailed = 3,
    WithdrawalDecline = 4,
    StakePurchase = 5,
    StakeResult = 6,
    StakeRefund = 7,
    AdminCredit = 8,
    AdminDebit = 9,
    PrizeSettlement = 10,
    StakeSale = 11,
    PromotionCredit = 12,
    StakeBounty = 13,
    GGAdminCredit = 14,
    GGAdminDebit = 15,
    PaymentRefund = 16,
    PaymentRefundFailed = 17,
    PurchaseCanceled = 18,
    StakeBountyJackpot = 21
}

export enum PaymentType {
    Deposit = 0,
    Withdrawal = 1
}

export enum PaymentReconciliationType {
    Deposit = 0,
    Withdrawal = 1,
    Refund = 2,
    Unknown = 99
}

export enum PaymentProcessStatus {
    Created = 0,
    Pending = 1,
    Reviewing = 2,
    Confirmed = 3,
    Processing = 4,
    Completed = 5,
    Failed = 6,
    Declined = 7,
    Canceled = 8
}

export enum SystemTransactionConfigStatus {
    Off = 0,
    On = 1,
    Maintenance = 2
}

export enum BalanceTransferType {
    Credit = 0,
    Debit = 1
}

export enum CoinPaymentsCurrencyStatus {
    Online = 0,
    Maintenance = 1
}

export enum CoinPaymentsCapabilityType {
    Payments = 0,
    Wallet = 1,
    Transfers = 2,
    Convert = 3,
    DestinationTag = 4
}

export enum CoinPaymentsCommandType {
    Rates,
    GetCallbackAddress,
    CreateWithdrawal,
    CreateTransaction,
    GetTxInfo,
    Balances
}

export enum PaymentCoinPaymentsResultCode {
    Unknown,
    Failed,
    Pending,
    Success,
    Canceled
}

export enum TournamentCurrency {
    USD = 0,
    EUR = 1,
    GBP = 2,
    RUB = 3,
    JPY = 4,
    KRW = 5,
    CNY = 6,
    HKD = 7,
    TWD = 8,
    KHR = 9,
    VND = 10,
    PHP = 11,
    INR = 12,
    THB = 13,
    IDR = 14,
    MYR = 15,
    SGD = 16,
    SEK = 17,
    NOK = 18,
    CHF = 19,
    CAD = 20,
    AMD = 21,
    DKK = 22,
    AUD = 25,
    BRL = 26
}

export enum S3ObjectType {
    TEMP = 0,
    VENUE = 1,
    PSP = 2,
    BRAND = 3,
    SERIES = 4
}

export enum SlackApiCommandType {
    PostMessage
}

export enum SlackChannel {
    P5Notification
}

export enum PromotionStatus {
    Pending = 0,
    Completed = 1,
    NotExist = 2,
    Duplicated = 3,
    Invalid = 4
}

export enum OriginType {
    P5 = 0,
    GG = 1,
    WSOPCA = 2
}

export enum TournamentStatus {
    Registering = 0,
    Running = 1,
    Completed = 2
}

export enum GGTournamentStatus {
    Created = 0,
    Announced = 1,
    Registering = 2,
    Running = 3,
    Canceled = 4,
    Completed = 5,
    Removed = 6,
    Obsolete1 = 7,
    Obsolete2 = 8,
    Stopped = 9,
    Refunding = 10,
    Invalid = 11,
    Waiting = 12
}

export enum StakeListType {
    MyStake,
    StakeAgain,
    Series,
    FeturedPlayer,
    Tournament,
    RisingStar
}

export enum SnsService {
    FaceBook,
    Twitter
}

export enum StakeMailType {
    StakeCreated = 0,
    StakeSoldOut = 1,
    StakeCompleted = 2,
    StakeCanceled = 3
}

export enum MailSendProgress {
    Processing = 0,
    Success = 1,
    Failed = 2
}

export enum AdminListColumnSort {
    AdminId,
    CreatedAt,
    AdminEmail,
    AdminName,
    ParentAdminName,
    IsDeleted
}

export enum AdminLoginHistoryListColumnSort {
    AdminLoginHistoryId,
    AdminId,
    CreatedAt,
    IsFailed,
    IPAddress,
    AdminName,
    AdminEmail
}

export enum MemberListColumnSort {
    PlayerId,
    CreatedAt,
    PlayerName,
    PlayerEmail,
    MemberClass,
    IsDeleted,
    IsDemo,
    Balance,
    P5Id
}

export enum MemberLoginHistoryListColumnSort {
    PlayerLoginHistoryId,
    PlayerId,
    PlayerName,
    PlayerEmail,
    IsFailed,
    IPAddress,
    Country,
    CreatedAt,
    MemberClass
}

export enum VenueListColumnSort {
    VenueId,
    CreatedAt,
    VenueName,
    TimeZone,
    VenueAddress,
    TournamentCount,
    StakeCount
}

export enum TournamentListColumnSort {
    TournamentId,
    CreatedAt,
    VenueName,
    StartTime,
    Title,
    BuyIn,
    Guarantee
}

export enum StakeListColumnSort {
    StakeId,
    StartTime,
    Progress,
    CreatedAt,
    StakersCount
}

export enum PaymentHistoryColumnSort {
    TXID,
    PaymentType,
    PlayerName,
    Amount,
    UpdateAt
}

export enum PaymentReconciliationColumnSort {
    Id,
    TXId,
    PaymentType,
    PlayerName,
    Amount,
    UpdateAt
}

export enum AdminAuditListColumnSort {
    AuditId,
    CreatedAt,
    AdminId,
    AdminName,
    AdminEmail,
    Contents
}

export enum MemberAuditListColumnSort {
    AuditId,
    CreatedAt,
    PlayerId,
    PlayerName,
    PlayerEmail,
    Contents
}

export enum PaymentReportColumnSort {
    Date,
    DepositAmount,
    Deposits,
    Depositers,
    WithdrawalAmount,
    Withdrawals,
    Withdrawalers,
    NetDeposit,
    PSPFee,
    PSPName,
    PlayerId,
    PlayerName,
    PlayerEmail
}

export enum StakingReportColumnSort {
    Date,
    TotalStakings,
    OpenStakings,
    ClosedStakings,
    TotalSold,
    TotalPurchases,
    TotalStakers,
    Sales,
    Prize,
    Profits,
    Roi,
    VenueName,
    TournamentId,
    TournamentName,
    SellerId,
    SellerName,
    SellerEmail
}

export enum MemberStakingReportColumnSort {
    PlayerId,
    PlayerName,
    PlayerEmail,
    PurchaseCount,
    PurchaseAmount,
    PrizeCount,
    PrizeAmount,
    Roi,
    DepositCount,
    DepositAmount,
    WithdrawalCount,
    WithdrawalAmount,
    NetDeposit,
    IsDeleted
}

export enum PromotionTransactionColumnSort {
    PromotionTransactionId,
    RequestEmail,
    AdminId,
    AdminName,
    PlayerId,
    PlayerName,
    PlayerEmail,
    Status,
    CreatedAt,
    UpdatedAt
}

export enum SellerListColumnSort {
    SellerId,
    OriginType,
    SellerKey,
    SellerName,
    SellerEmail,
    IsActive,
    IsDeleted,
    IsDemo,
    CreatedAt,
    UpdatedAt,
    Order
}

export enum BrandListColumnSort {
    BrandId,
    BrandName,
    BrandLinkUrl,
    TournamentCount,
    StakeCount
}

export enum SeriesListColumnSort {
    SeriesId,
    SeriesName,
    VenueName,
    BrandName,
    SeriesBeginAt,
    TournamentCount,
    StakeCount
}

export enum SearchStakeListColumnSort {
    StartTime,
    CreatedAt,
    Markup,
    BuyIn,
    Available
}

export enum StakeViewMoreListColumnSort {
    StartTime,
    CreatedAt,
    MarkupLow,
    MarkupHigh,
    BuyInLow,
    BuyInHigh,
    SellerName,
    AvailableLow,
    AvailableHigh
}

export enum PaymentRefundTransactionListColumnSort {
    RefundTxId,
    PaymentTxId,
    ProcessStatus,
    CreatedAt
}

export enum ErrorCodes {
    SUCCESS = 'SUCCESS',
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    ACCESS_DENIED = 'ACCESS_DENIED',
    SESSION_EXPIRED = 'SESSION_EXPIRED',
    INVALID_REQUEST = 'INVALID_REQUEST',
    INVALID_RESPONSE = 'INVALID_RESPONSE',
    INVALID_P5_SESSION = 'INVALID_P5_SESSION',
    INVALID_SESSION = 'INVALID_SESSION',
    INVALID_PLAYER = 'INVALID_PLAYER',
    INVALID_ADMIN = 'INVALID_ADMIN',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    INVALID_NAME_RULE = 'INVALID_NAME_RULE',
    INVALID_EMAIL_RULE = 'INVALID_EMAIL_RULE',
    INVALID_PASSWORD_RULE = 'INVALID_PASSWORD_RULE',
    INVALID_PERMISSION = 'INVALID_PERMISSION',
    NAME_ALREADY_EXISTS = 'NAME_ALREADY_EXISTS',
    EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
    DATA_ALREADY_EXISTS = 'DATA_ALREADY_EXISTS',
    DATA_NOT_FOUND = 'DATA_NOT_FOUND',
    DATA_NOT_EMPTY = 'DATA_NOT_EMPTY',
    NOT_ENOUGH_BALANCE = 'NOT_ENOUGH_BALANCE',
    FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED',
    FILE_UPLOAD_PARTIALLY_FAILED = 'FILE_UPLOAD_PARTIALLY_FAILED',
    PSPTXID_EMPTY = 'PSPTXID_EMPTY',
    FILE_DOWNLOAD_FAILED = 'FILE_DOWNLOAD_FAILED',
    PAYMENT_DEPOSIT_CANCELED = 'PAYMENT_DEPOSIT_CANCELED',
    PAYMENT_DEPOSIT_FAILED = 'PAYMENT_DEPOSIT_FAILED',
    PAYMENT_WITHDRAWAL_FAILED = 'PAYMENT_WITHDRAWAL_FAILED',
    PAYMENT_REQUEST_FAILED = 'PAYMENT_REQUEST_FAILED',
    PAYMENT_PENDING = 'PAYMENT_PENDING',
    AMOUNT_MIN_ERROR = 'AMOUNT_MIN_ERROR',
    AMOUNT_MAX_ERROR = 'AMOUNT_MAX_ERROR',
    PARTIAL_FAILED = 'PARTIAL_FAILED',
    CONCURRENCY_FAILED = 'CONCURRENCY_FAILED',
    INVALID_CARD_INFO = 'INVALID_CARD_INFO',
    INVALID_TRANSACTION = 'INVALID_TRANSACTION',
    WITHDRAWAL_TRANSACTION_EXISTS = 'WITHDRAWAL_TRANSACTION_EXISTS',
    BALANCE_UPDATE_FAILED = 'BALANCE_UPDATE_FAILED',
    TOURNAMENT_STARTED = 'TOURNAMENT_STARTED',
    EXCEEDED_THE_MAXIMUM = 'EXCEEDED_THE_MAXIMUM',
    EXCEEDED_THE_LIMIT = 'EXCEEDED_THE_LIMIT',
    INVALID_CURRENCY = 'INVALID_CURRENCY',
    REMOTE_SERVER_ERROR = 'REMOTE_SERVER_ERROR',
    INVALID_PARAMETER = 'INVALID_PARAMETER',
    INVALID_DESTINATION_TAG = 'INVALID_DESTINATION_TAG',
    INVALID_PAYMENT_CONFIGURATION = 'INVALID_PAYMENT_CONFIGURATION',
    INVALID_PAYMENT_METHOD = 'INVALID_PAYMENT_METHOD',
    INVALID_DATA_FORMAT = 'INVALID_DATA_FORMAT',
    SOLD_OUT_STAKE = 'SOLD_OUT_STAKE',
    PAYMENT_REFUND_FAILED = 'PAYMENT_REFUND_FAILED',
    GG_UNDER_MAINTERNANCE = 'GG_UNDER_MAINTERNANCE',
    WSOPCA_UNDER_MAINTERNANCE = 'WSOPCA_UNDER_MAINTERNANCE'
}

export interface ErrorResponse {
    errorCode: string;
    description: string;
    errorParameters: string[];
    exception: string;
}

export enum GPErrorCode {
    SUCCESS = 0,
    ACCESS_DENIED = 1,
    INTERNAL_ERROR = 2,
    INVALID_REQUEST = 3,
    BRAND_NOT_FOUND = 4,
    ACCOUNT_NOT_FOUND = 5,
    TRANSACTION_ALREADY_EXISTS = 6,
    INSUFFICIENT_BALANCE = 7,
    INVALID_TRANSACTION_ORDER = 8,
    TRANSACTION_ALREADY_CANCELLED = 9,
    TRANSACTION_NOT_FOUND = 10,
    ACCOUNT_SUSPENDED = 11,
    ACCOUNT_UNVERIFIED = 12,
    NICKNAME_NOT_FOUND = 13,
    NICKNAME_ALREADY_EXISTS = 14,
    NICKNAME_PROHIBITED = 15
}

export enum GSErrorCode {
    SUCCESS,
    UNKNOWN,
    INTERNAL_ERROR,
    TOURNEY_STAKING_NOT_ALLOW,
    TOURNEY_SELLER_NOT_FOUND,
    TOURNEY_SELLER_ITEM_NOT_FOUND,
    TOURNEY_INVALID_SHARE_AMOUNT,
    TOURNEY_SOLDOUT_SHARE,
    TOURNEY_EXCEEDED_BUY_COUNT,
    INVALID_REQUEST,
    TOURNEY_NOT_FOUND,
    ACCOUNT_NOT_FOUND,
    TOO_MANY_REQUESTS,
    FAIL_CREATE_ACCOUNT,
    UNAUTHORIZED
}

export enum PaymentReportGroupCategory {
    Day,
    Week,
    Month,
    PSP,
    Player
}

export enum StakingReportGroupCategory {
    Day,
    Week,
    Month,
    Tournament,
    Seller
}

export enum AuthenticationMethod {
    DontUse = 0,
    Email = 1,
    GoogleOtp = 2
}

export enum SecurityLevel {
    Off = 0,
    Low = 1,
    Medium = 2,
    High = 3
}

export enum TwoFactorHistoryColumnSort {
    Id = 0,
    AuthenticationMethod = 1,
    AuthenticationResult = 2,
    AuthenticationLocation = 3
}

export enum AuthenticationResultType {
    Failed = 0,
    Success = 1,
    Expired = 2
}

export enum AuthenticationLocationType {
    Login = 0,
    ChangePassword = 1,
    WithdrawalRequest = 2,
    CoinAddressChange = 3,
    OnWithdrawlPassword = 4
}

export enum ReconciliationStatus {
    Ready = 0,
    Go = 1,
    TxIdNotFoundPSK = 2,
    AmountDiff = 3
}

export enum BannerTarget {
    Blank = 0,
    Self = 1,
    Parent = 2,
    Top = 3
}

export enum SellerTier {
    Normal = 0,
    Featured = 1,
    SuperFeatured = 2
}

export enum GenderType {
    Undefined = 0,
    Male = 1,
    Female = 2
}
